import React from 'react'
import PropTypes from 'prop-types'

import orangeLine from '../../../images/orange-line.svg'
import checkmark from '../../../icons/checkmark-green.svg'

const IntroAlt = ({
  children,
  showTitle,
  showSubTitle,
  bgImage,
  colType,
  title,
  subTitle,
  imgTitle,
  imgText,
  classNames,
}) => {
  classNames = 'intro-section ' + classNames
  return (
    <div className={classNames}>
      <div className="two-col">
        <div className="left-col" style={{ backgroundImage: `url('${bgImage}')` }}>
          <h1 className="">{imgTitle}</h1>
          <div className="img-text-bg hidden-mobile">
            <p>{imgText}</p>
          </div>
        </div>
        <div className={colType} style={{ backgroundImage: `url('${orangeLine}')` }}>
          {showTitle && (
            <div className="title-holder">
              <h3 dangerouslySetInnerHTML={{ __html: title }} />
              {showSubTitle && (
                <p className="sub-title hidden-mobile">
                  <span>
                    <img src={checkmark} alt="Checkmark" height="16" width="16" />
                  </span>
                  {subTitle}
                </p>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

IntroAlt.propTypes = {
  children: PropTypes.node,
  showTitle: PropTypes.bool,
  showSubTitle: PropTypes.bool,
  bgImage: PropTypes.string,
  imgTitle: PropTypes.string,
  imgText: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classNames: PropTypes.string,
}

IntroAlt.defaultProps = {
  showTitle: true,
  showSubTitle: true,
  bgImage: '/images/kid-bg.jpg',
  colType: 'right-col',
  title: 'Just answer a few questions for your free life insurance quote.',
  subTitle: 'Getting a quote is 100% free, fast, and secure.',
  imgTitle: 'Protect what matters most, when it matters most.',
  imgText: 'Life gets complicated, but insuring it shouldn’t be.',
  classNames: '',
}

export default IntroAlt
