import React, { Component } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './styles.module.scss'

import norton from '../../../images/Norton-Logo.svg'
import mcafee from '../../../images/McAfee-Logo.svg'
import bbb from '../../../images/BBB-Logo.svg'

class Zip extends Component {
  constructor(props) {
    super(props)
    this.state = { value: '', disabled: 'disabled' }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.captive.data.zip !== this.props.captive.data.zip) {
      const zip = this.props.captive.data.zip
      this.setState({ value: zip, disabled: zip.length !== 5 })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.submitZip(this.state.value)
    this.props.nextStep('zip')
    if (this.props.shouldNavigate) {
      navigate('/apply/dobmonth')
    }
  }
  handleChange(event) {
    const isDisabled =
      event.target.value !== '' && event.target.value.length === 5 ? '' : 'disabled'
    this.setState({ value: event.target.value, disabled: isDisabled })
  }

  render = () => (
    <div>
      <h4 className="h4-alt">Please enter your zip code.</h4>
      <form
        action="#"
        onSubmit={this.handleSubmit}
        className="form-validation"
        noValidate="novalidate"
      >
        <div className="row">
          <div className="col full-width">
            <label htmlFor="zip" className="hidden">
              Zip
            </label>
            <input
              maxLength="5"
              type="text"
              id="zip"
              data-required="true"
              value={this.state.value}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="btn-wrap text-center">
          <button
            type="submit"
            disabled={this.state.disabled}
            className={`btn` + (this.state.disabled ? ` disabled` : ``)}
          >
            Continue
          </button>
        </div>
        <ul className={clsx('security-list', this.props.blue && styles.blue)}>
          <li>
            <img src={norton} alt="description of thing" />
          </li>
          <li>
            <img src={mcafee} alt="description of thing" />
          </li>
          <li>
            <img src={bbb} alt="description of thing" />
          </li>
        </ul>
      </form>
    </div>
  )
}

Zip.propTypes = {
  shouldNavigate: PropTypes.bool,
}

Zip.defaultProps = {
  shouldNavigate: false,
}

export default Zip
